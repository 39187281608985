import React, { useEffect, useState } from "react";
import { animated as a, useSpring } from "@react-spring/web";
import CustomBox from "../Components/CustomBox";
import { MdWebAsset } from "react-icons/md";
import { MdPhoneAndroid } from "react-icons/md";
import { SiWoo, SiWoocommerce } from "react-icons/si";

const Works = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const triggerPoint = window.innerHeight / 0.5; // Punto di attivazione
      const scrolled = window.scrollY;

      // Cambia la visibilità del div quando si scorre abbastanza in basso
      if (scrolled > triggerPoint) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Definizione dell'animazione
  const props = useSpring({
    transform: isVisible ? "translateX(0)" : "translateX(-100%)",
    opacity: isVisible ? 1 : 0,
    config: { tension: 100, friction: 30 }, // Leggero ease-in
  });

  return (
    <div className="w-full h-full gradient-main text-[#F5F5F7] p-10">
      <h3 className="text-center w-full font-semibold text-4xl mb-5 p-3">
        Lavori
      </h3>

      <a.div
        style={props}
        id="works"
        className="flex flex-row flex-wrap items-center justify-center gap-10"
      >
        <CustomBox
          title={"Studio Medico 33"}
          image="/media/sm33.png"
          bgColor={"white"}
        >
          <div className="flex flex-col h-full items-center place-content-evenly">
            <div className="text-md lg:text-xl flex flex-col lg:flex-row items-center text-start gap-1 lg:gap-3 p-3">
              <span className="text-[20px] lg:text-[50px] border-b-2 lg:border-b-0 lg:border-r-2 p-2">
                <MdWebAsset />{" "}
              </span>
              Sviluppo di Web App per la gestione dei medici e delle
              prenotazioni
            </div>

            <div className="text-md lg:text-xl flex flex-col-reverse lg:flex-row items-center text-start gap-3 p-3">
              Sviluppo di una applicazione iOs e Android per la prenotazione
              delle visite{" "}
              <span className="text-[20px] lg:border-b-0 lg:text-[50px] border-b-2 lg:border-l-2 p-2">
                <MdPhoneAndroid />{" "}
              </span>
            </div>
          </div>
        </CustomBox>

        <CustomBox
          title={"Spesup.it"}
          text={"Spesup.it"}
          image="/media/spesup.png"
          textColor="white"
          bgColor={"#5DA201"}
        >
          <div className="flex flex-col h-full items-center place-content-evenly">
            <div className="text-md lg:text-xl flex flex-col lg:flex-row items-center text-start gap-1 lg:gap-3 p-3 ">
              <span className="text-[20px] lg:text-[50px] border-b-2 lg:border-r-2 lg:border-b-0 p-2">
                <MdWebAsset />{" "}
              </span>
              Sviluppo di Web App per la gestione dei prodotti e la
              sincronizzazione con servizi di terze parti per il recupero di
              descrizioni e immagini dei prodotti
            </div>

            <div className="text-md lg:text-xl flex flex-col-reverse lg:flex-row items-center text-start gap-3 p-3">
              Utilizzo di API di WooCommerce e Wordpress per l'integrazione con
              il sito web con la possibilità di aggiungere, modificare,
              rimuovere prodotti, promozioni, categorie{" "}
              <span className="text-[20px] lg:border-b-0 lg:text-[50px] border-b-2 lg:border-l-2 p-2">
                <SiWoocommerce />{" "}
              </span>
            </div>
          </div>
        </CustomBox>

        <CustomBox
          title={"Farmacia Artemis"}
          text={"Farmacia Artemis"}
          image="/media/artemis.png"
          textColor="black"
          bgColor={"white"}
        >
          <div className="flex flex-col h-full items-center place-content-evenly">
            <div className="text-md lg:text-xl flex flex-col lg:flex-row items-center text-start gap-1 lg:gap-3 p-3">
              <span className="text-[20px] lg:text-[50px] border-b-2 lg:border-r-2 p-2 lg:border-b-0">
                <MdWebAsset />{" "}
              </span>
              Sviluppo di Web App per la gestione dei servizi e delle
              prenotazioni, con la possibilità di inviare notifiche agli utenti
              che hanno l'applicazione installata
            </div>

            <div className="text-md lg:text-xl flex flex-col-reverse lg:flex-row items-center text-start gap-3 p-3">
              Sviluppo di una applicazione iOs e Android per la prenotazione
              delle visite{" "}
              <span className="text-[20px] lg:text-[50px] border-b-2 lg:border-b-0 lg:border-l-2 p-2">
                <MdPhoneAndroid />{" "}
              </span>
            </div>
          </div>
        </CustomBox>

        <CustomBox
          title={"Farmacia del Tricolle"}
          text={"Farmacia del Tricolle"}
          image="/media/tricolle.png"
          textColor="white"
          bgColor={"#988570"}
        >
          <div className="flex flex-col h-full items-center place-content-evenly">
            <div className="text-md lg:text-xl flex flex-col lg:flex-row items-center text-start gap-1 lg:gap-3 p-3">
              <span className="text-[20px] lg:text-[50px] border-b-2 lg:border-r-2 p-2 lg:border-b-0">
                <MdWebAsset />{" "}
              </span>
              Sviluppo di Web App per la gestione dei servizi e delle
              prenotazioni, con la possibilità di inviare notifiche agli utenti
              che hanno l'applicazione installata
            </div>

            <div className="text-md lg:text-xl flex flex-col-reverse lg:flex-row items-center text-start gap-3 p-3">
              Sviluppo di una applicazione iOs e Android per la prenotazione
              delle visite{" "}
              <span className="text-[20px] lg:text-[50px] border-b-2 lg:border-b-0 lg:border-l-2 p-2">
                <MdPhoneAndroid />{" "}
              </span>
            </div>
          </div>
        </CustomBox>

        <CustomBox
          title={"Community Library Orsara di Puglia"}
          text={"Community Library Orsara di Puglia"}
          image="/media/community.webp"
          textColor="white"
          bgColor={"#F13B3A"}
        >
          <div className="flex flex-col h-full items-center place-content-evenly">
            <div className="text-md lg:text-xl flex flex-col lg:flex-row items-center text-start gap-1 lg:gap-3 p-3">
              <span className="text-[20px] lg:text-[50px] border-b-2 lg:border-r-2 p-2 lg:border-b-0">
                <MdWebAsset />{" "}
              </span>
              Sviluppo di Web App per la gestione di libri e dischi. Gestione
              dei prestiti con sistema di gamification integrato nel sito della
              biblioteca
            </div>

            <p>app.communitylibraryaliorsaradipuglia.it</p>
          </div>
        </CustomBox>

        <CustomBox
          title={"Consulente IA Atelier Pantheon"}
          text={"Consulente IA Atelier Pantheon"}
          image="/media/pantheon.webp"
          textColor="black"
          bgColor={"#fff"}
        >
          <div className="flex flex-col h-full items-center place-content-evenly">
            <div className="text-md lg:text-xl flex flex-col lg:flex-row items-center text-start gap-1 lg:gap-3 p-3">
              <span className="text-[20px] lg:text-[50px] border-b-2 lg:border-r-2 p-2 lg:border-b-0">
                <MdWebAsset />{" "}
              </span>
              Sviluppo di una Web App personalizzata con integrazione di ChatGPT
              per consulenza su stile matrimoni e abiti.
            </div>

            <div className="text-md lg:text-xl flex flex-col lg:flex-row items-center text-start gap-1 lg:gap-3 p-3">
              Sistema di login con token JWT per integrare applicazione mobile
              iOS e Android con un sistema di fidelity card collegato alla
              WebApp di consulenza.
              <span className="text-[20px] lg:text-[50px] border-b-2 lg:border-l-2 p-2 lg:border-b-0">
                <MdPhoneAndroid />{" "}
              </span>
            </div>

            <p>consulente.atelierpantheon.it</p>
          </div>
        </CustomBox>
      </a.div>
    </div>
  );
};

export default Works;
