import React, { useState } from "react";
import Button from "./Button";

const Navbar = ({ darkModeButton }) => {
  const [tabOpen, setTabOpen] = useState(false);

  return (
    <nav className="lg:m-3 lg:rounded-full secondary-color shadow w-full max-w-6xl text-white fixed z-50">
      <div className="px-8 mx-auto max-w-7xl">
        <div className="flex items-center justify-between h-16">
          <div className="w-full justify-between flex items-center">
            <a className="flex-shrink-0" href="/">
              <h2 className="text-2xl font-semibold">Davide Aprile</h2>
            </a>
            <div className="hidden md:block">
              <div className="flex items-baseline ml-10 space-x-4 text-white">
                <Button text={"Home"} href="/#home" />

                <Button text={"Su di me"} href="/#about" />

                <Button text={"Competenze"} href="/#skills"/>

                <Button text={"Lavori"} href="/#works"/>

                <Button text={"Contatti"} />

                {darkModeButton}
              </div>
            </div>
          </div>
          <div className="flex -mr-2 md:hidden">
            <button
              onClick={() => setTabOpen(!tabOpen)}
              className="text-white inline-flex items-center justify-center p-2 rounded-md focus:outline-none"
            >
              <svg
                width="20"
                height="20"
                fill="currentColor"
                className="w-8 h-8"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
        <div className={`${tabOpen ? "block" : "hidden"} md:hidden p-5`}>
          <div className="flex flex-col items-baseline ml-10 space-x-4 text-white">
            <Button text={"Home"} href="/#home" />

            <Button text={"Su di me"} href="/#about" />

            <Button text={"Lavori"} />

            <Button text={"Contatti"} />

            {darkModeButton}
          </div>
        </div>
    </nav>
  );
};

export default Navbar;
