import React from "react";
import { useSpring, animated } from '@react-spring/web';

const PrimaryButton = ({ icon, text, href, className, onMouseEnter, onMouseLeave }) => {
  const [style, setStyle] = useSpring(() => ({
    transform: "translateY(0px)"
  }));

  return (
    <animated.a
      style={style}
      onMouseEnter={() =>
        setStyle({
          transform: "translateY(-10px)", // Sollevamento verso l'alto
        })
      }
      onMouseLeave={() =>
        setStyle({
          transform: "translateY(0px)", // Torna alla posizione originale
        })
      }
      href={href}
      className={"text-white p-3 rounded-full flex flex-row gap-3 items-center text-lg " + className}
      target="_blank"
      rel="noreferrer"
    >
      {icon} {text}
    </animated.a>
  );
};

export default PrimaryButton;
