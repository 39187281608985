import React, { useEffect, useState } from "react";
import { FaBirthdayCake, FaInstagram, FaMapMarkerAlt } from "react-icons/fa";
import { FaEnvelope, FaFacebook, FaLinkedin, FaUser } from "react-icons/fa6";
import PrimaryButton from "../Components/PrimaryButton";
import { useSpring, animated } from "@react-spring/web";

const About = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const triggerPoint = window.innerHeight / 3; // Punto di attivazione
      const scrolled = window.scrollY;

      // Cambia la visibilità del div quando si scorre abbastanza in basso
      if (scrolled > triggerPoint) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Definizione dell'animazione
  const props = useSpring({
    transform: isVisible ? "translateX(0)" : "translateX(-100%)",
    opacity: isVisible ? 1 : 0,
    config: { tension: 100, friction: 25 }, // Leggero ease-in
  });

  return (
    <>
      <div
        id="about"
        name="about"
        className="p-5 py-20 gradient-main w-full flex flex-col place-content-center items-center text-[#F5F5F7]"
      >
        <animated.div style={props} className="text-white md:rounded-t-2xl">
          <h3 className="font-semibold text-4xl mb-5 p-3 ">Su di me</h3>
        </animated.div>
        <div className="flex flex-row place-content-center md:place-content-between gap-5 p-6 flex-wrap-reverse">
          <div>
            <animated.div style={props} className="flex flex-col gap-5">
              <div className="flex flex-row gap-3 text-xl font-semibold">
                <FaMapMarkerAlt className="" />{" "}
                <h4 className="">Ariano Irpino, AV</h4>
              </div>

              <div className="flex flex-row gap-3 text-xl font-semibold">
                <FaBirthdayCake className="" />{" "}
                <h4 className="">3 Luglio 2000</h4>
              </div>

              <div className="flex flex-row gap-3 text-xl font-semibold">
                <FaEnvelope className="" />{" "}
                <h4 className="">davideaprile2000@gmail.com</h4>
              </div>

              <div className="flex flex-row gap-3 text-xl font-semibold">
                <FaUser className="" /> <h4 className="">Uomo</h4>
              </div>
            </animated.div>
            <animated.div style={props} className="flex flex-row gap-5 m-5 flex-wrap items-center place-content-center">
              <PrimaryButton
                icon={<FaFacebook />}
                text={"Facebook"}
                href={"https://www.facebook.com/davide.aprile.92/"}
              />
              <PrimaryButton
                icon={<FaInstagram />}
                text={"Instagram"}
                href={"https://www.instagram.com/davideaprile00/"}
              />
              <PrimaryButton
                icon={<FaLinkedin />}
                text={"LinkedIn"}
                href={"https://www.linkedin.com/in/davide-aprile-60b75918a/"}
              />
            </animated.div>
          </div>
          <animated.div style={props}>
            <img
              className="rounded-full h-44 lg:h-96"
              src="/media/foto.png"
              alt="profile_image"
            />
          </animated.div>
        </div>
      </div>
    </>
  );
};

export default About;
