import { useState } from "react";
import "./App.css";
import Navbar from "./Components/Navbar";
import { FaSun } from "react-icons/fa";
import { FaMoon } from "react-icons/fa6";
import Hero from "./Pages/Hero";
import About from "./Pages/About";
import Skills from "./Pages/Skills";
import Works from "./Pages/Works";

function App() {
  const [darkMode, setDarkMode] = useState(true);

  return (
    <div
      className={`bg-[#fafaeb] overflow-x-hidden dark:bg-[#141219] flex flex-col items-center transition-all duration-300 ${
        darkMode ? "dark" : ""
      }`}
    >
      <Navbar
        darkModeButton={
          <button onClick={() => setDarkMode(!darkMode)} className="text-white">
            {darkMode ? <FaSun /> : <FaMoon />}
          </button>
        }
      />
      <Hero/>
      <About />
      <Skills/>
      
      <Works/>

      {/* <h1 className="text-2xl font-bold">Work in progress...</h1> */}
    </div>
  );
}

export default App;
