import { CameraControls, ContactShadows, Environment } from "@react-three/drei";
import { useEffect, useRef, useState } from "react";
import { Avatar } from "./Avatar";

// const Dots = (props) => {
//   const { loading } = useChat();
//   const [loadingText, setLoadingText] = useState("");
//   useEffect(() => {
//     if (loading) {
//       const interval = setInterval(() => {
//         setLoadingText((loadingText) => {
//           if (loadingText.length > 2) {
//             return ".";
//           }
//           return loadingText + ".";
//         });
//       }, 800);
//       return () => clearInterval(interval);
//     } else {
//       setLoadingText("");
//     }
//   }, [loading]);
//   if (!loading) return null;
//   return (
//     <group {...props}>
//       <Text fontSize={0.14} anchorX={"left"} anchorY={"bottom"}>
//         {loadingText}
//         <meshBasicMaterial attach="material" color="black" />
//       </Text>
//     </group>
//   );
// }; 

export const Experience = () => {
  const cameraControls = useRef();
  // const { cameraZoomed } = useChat();
  const [controls, setControls] = useState(true);

  useEffect(() => {
    cameraControls.current.setLookAt(2, 1.5, 3, 0, 1.5, 0);
    setTimeout(() => {
      setControls(false);
    }, 200);
  }, []);

  // useEffect(() => {
  //   if (cameraZoomed) {
  //     cameraControls.current.setLookAt(0, 1.5, 1.5, 0, 1.5, 0, true);
  //   } else {
  //     cameraControls.current.setLookAt(0, 2.2, 5, 0, 1.0, 0, true);
  //   }
  // }, [cameraZoomed]);

  return (
    <>
      <CameraControls enabled={controls} ref={cameraControls} />
      <Environment preset="sunset" />
      {/* <Suspense>
          <Dots position-y={1.75} position-x={-0.02} />
        </Suspense> */}
      <Avatar />
      <ContactShadows opacity={0.7} />
    </>
  );
};
