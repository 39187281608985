import React from "react";
import { Canvas } from "@react-three/fiber";
import { Experience } from "../Avatar/Experience";
import { Leva } from "leva";

const Hero = () => {
  return (
    <div id="home" className="flex flex-col">
      <div className="flex flex-row place-content-center items-center flex-wrap-reverse">
        <div className="flex flex-col">
          <h1 className="text-[#242424] dark:text-[#F5F5F7] text-4xl md:text-6xl lg:text-7xl font-bold">
            Ciao sono Davide
          </h1>
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold gradient-text">
            Full-stack web developer - Game developer
          </h2>
        </div>

        <div className="h-dvh w-96 z-0">
          <Leva hidden />
          <Canvas shadows camera={{ position: [0, 0, 1], fov: 30 }}>
            <Experience />
          </Canvas>
        </div>
      </div>
    </div>
  );
};

export default Hero;
