import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";

const CustomBox = ({ title, children, image, textColor = "black", bgColor }) => {
  const [style, setStyle] = useSpring(() => ({
    transform: "translateY(0px)",
  }));

  const [hovered, setHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <animated.button
        style={{
          ...style,
          backgroundImage: `url(${image})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          width: "700px",
          height: "600px",
          zIndex: "2",
          backgroundColor: bgColor,
        }}
        onMouseEnter={() => {
          setStyle({
            transform: "translateY(-10px)", // Sollevamento verso l'alto
          });
          setHovered(true);
        }}
        onMouseLeave={() => {
          setStyle({
            transform: "translateY(0px)", // Torna alla posizione originale
          });
          setHovered(false);
        }}
        onClick={() => setIsExpanded(!isExpanded)}
        className="rounded-2xl p-4 flex items-end place-content-start"
      >
        <div
          className={`absolute rounded-2xl inset-0 transition-opacity duration-300 ${
            hovered ? "opacity-100" : "opacity-0"
          }`}
          style={{
            background:
              "linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
          }}
        />

        <animated.div
          className={"flex flex-row place-content-between items-center w-full"}
          style={{
            ...style,
            backgroundColor: "rgba(0, 0, 0, 0.0)",
            color: "white",
            textAlign: "center",
          }}
        >
          <h3 className="text-4xl font-bold" style={{ color: textColor }}>
            {title}
          </h3>
          {hovered ? <div style={{ color: textColor }} className="text-nowrap">Scopri di più &gt;</div> : ""}

        </animated.div>
        {isExpanded && (
          <div
            style={{ backgroundColor: bgColor, color: textColor }}
            className="absolute rounded-lg inset-0 z-50 flex items-center justify-center bg-opacity-80"
          >
            <div className="w-full md:w-3/4 md:h-3/4 rounded-lg p-8">
              <h2 className="text-xl lg:text-3xl font-bold mb-4">{title}</h2>
              <p className="text-wrap">
                {children}
              </p>
            </div>
          </div>
        )}
      </animated.button>
    </>
  );
};

export default CustomBox;
