import React from 'react'
import { useSpring, animated } from '@react-spring/web';

const Button = ({text, href}) => {
  const [style, setStyle] = useSpring(() => ({
    transform: "translateY(0px)"
  }));

  return (
    <animated.a style={style}
    onMouseEnter={() =>
      setStyle({
        transform: "translateY(-10px)", // Sollevamento verso l'alto
      })
    }
    onMouseLeave={() =>
      setStyle({
        transform: "translateY(0px)", // Torna alla posizione originale
      })
    } href={href} className='py-2 px-4 rounded-full hover:text-[#d8d3ff] text-lg'>
      {text}
    </animated.a>
  )
}

export default Button
