import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";

const ButtonShow = ({ icon, hiddenText, textDirection, textColor, title }) => {
  const [isClicked, setIsClicked] = useState(false);

  const buttonAnimation = useSpring({
    transform: isClicked
      ? textDirection === "right"
        ? "translateX(-100px)"
        : "translateX(100px)"
      : "translateX(0px)",
  });

  const textAnimation = useSpring({
    opacity: isClicked ? 1 : 0,
    transform: isClicked ? "translateX(0px)" : "translateX(-20px)",
    config: { tension: 220, friction: 12 }, // Regola l'effetto dell'animazione
  });

  const [style, setStyle] = useSpring(() => ({
    transform: "translateY(0px)",
  }));

  return (
    <div className="text-[#242424] dark:text-[#F5F5F7] flex lg:flex-row flex-col place-content-center items-center gap-10">
      {textDirection === "left" && (
        <animated.div
          style={textAnimation}
          className={`ml-4 ${isClicked ? "block" : "hidden"}`}
        >
          <h2 className="text-xl font-bold">{title}</h2>
          {hiddenText}
        </animated.div>
      )}
      <animated.button
        onMouseEnter={() => {
          setIsClicked(true);
          setStyle({
            transform: "translateY(-10px)", // Sollevamento verso l'alto
          });
        }}
        onMouseLeave={() =>
          setStyle({
            transform: "translateY(0px)", // Torna alla posizione originale
          })
        }
        style={{
          ...buttonAnimation,
          ...style,
          color: isClicked ? textColor : "",
        }}
        onClick={() => setIsClicked(!isClicked)}
        className="text-[#242424] dark:text-[#F5F5F7] px-4 py-2 rounded text-[130px] border border-black dark:border-white rounded-lg"
      >
        {icon}
      </animated.button>
      {textDirection === "right" && (
        <animated.div
          style={textAnimation}
          className={`ml-4 ${isClicked ? "block" : "hidden"}`}
        >
          <h2 className="text-xl font-bold">{title}</h2>
          {hiddenText}
        </animated.div>
      )}
    </div>
  );
};

export default ButtonShow;
