import React, { useEffect, useState } from "react";
import { animated as a, useSpring } from "@react-spring/web";
import {
  FaLaravel,
  FaReact,
  FaUnity,
} from "react-icons/fa6";
import { SiFlutter } from "react-icons/si";
import ButtonShow from "../Components/ButtonShow";
import { TbApi } from "react-icons/tb";

const Skills = () => {
  const slides = [
    {
      id: 0,
      icon: <FaLaravel />,
      title: "Sviluppo Web Backend - Laravel",
      mainColor: "#fb503b",
      textDirection: "left",
      text: "Ho maturato una solida esperienza nello sviluppo backend, con un forte focus sull'utilizzo e lo sviluppo di API per garantire interazioni fluide e performanti tra diverse piattaforme. In particolare, ho una profonda conoscenza di Laravel, che utilizzo regolarmente per la creazione di applicazioni web robuste e scalabili. Grazie alla mia esperienza con questo framework, sono in grado di sviluppare soluzioni su misura, ottimizzare le prestazioni del sistema e implementare funzionalità avanzate per soddisfare le esigenze dei progetti.",
    },
    {
      id: 1,
      icon: <FaReact />,
      title: "Sviluppo Web Frontend - React",
      mainColor: "#53C1DE",
      textDirection: "right",
      text: "Ho accumulato significativa esperienza nel campo dello sviluppo frontend, specializzandomi in React per creare interfacce utente dinamiche e reattive. Utilizzo Tailwind CSS per gestire lo styling, sfruttando la sua versatilità e le sue classi utilitarie per costruire design moderni e ben strutturati. Questa combinazione mi consente di realizzare applicazioni web che non solo offrono un'ottima esperienza utente, ma sono anche facilmente scalabili e mantenibili.",
    },
    {
      id: 2,
      icon: <FaUnity />,
      mainColor: "#fff",
      textDirection: "left",

      title: "Sviluppo Videogames - Unity C#",
      text: "Ho una laurea in Programmazione di Videogames e un'ampia esperienza nello sviluppo di giochi utilizzando Unity. Sono in grado di progettare e realizzare giochi interattivi e coinvolgenti, sfruttando al meglio le potenzialità del motore di gioco Unity per creare esperienze immersive e di alta qualità.",
    },
    {
      id: 3,
      icon: <SiFlutter />,
      mainColor: "#06599B",
      textDirection: "right",
      title: "Sviluppo App mobile iOS e Android - Flutter",
      text: "Ho esperienza nello sviluppo di applicazioni mobili utilizzando Flutter. Sono in grado di progettare e costruire app cross-platform ad alte prestazioni, sfruttando la potenza di Flutter per garantire interfacce utente fluide e reattive su iOS e Android. La mia competenza include l'integrazione di funzionalità avanzate e la creazione di esperienze utente intuitive e coinvolgenti.",
    },
    {
      id: 3,
      icon: <TbApi />,
      mainColor: "red",
      textDirection: "left",
      title: "RESTful API",
      text: "Competenze avanzate nella progettazione e sviluppo di RESTful API scalabili, utilizzando Laravel per gestire la logica di business e formattare risposte JSON con API Resources. Implementazione sistemi di autenticazione sicuri, come JWT o OAuth2, e di integrazione delle API React con Axios. Esperienza nel testing e debugging delle API con strumenti come Postman, e nella gestione avanzata di errori e validazione su entrambe le estremità. È abile nell'ottimizzazione delle performance tramite pagination, filtering, caching e riduzione delle chiamate superflue nel frontend, garantendo così applicazioni efficienti e performanti.",
    },
  ];

  // const [index, setIndex] = useState(0);

  // const transitions = useTransition(slides[index], {
  //   key: slides[index].id,
  //   from: { opacity: 0, transform: "scale(0.8) translateX(100%)" },
  //   enter: { opacity: 1, transform: "scale(1) translateX(0%)" },
  //   leave: { opacity: 0, transform: "scale(0.8) translateX(-100%)" },
  //   config: { tension: 200, friction: 25 },
  // });

  // const handleNext = () => setIndex((state) => (state + 1) % slides.length);
  // const handlePrev = () =>
  // setIndex((state) => (state - 1 + slides.length) % slides.length);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const triggerPoint = window.innerHeight / 1; // Punto di attivazione
      const scrolled = window.scrollY;

      // Cambia la visibilità del div quando si scorre abbastanza in basso
      if (scrolled > triggerPoint) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const props = useSpring({
    transform: isVisible ? "translateX(0)" : "translateX(-100%)",
    opacity: isVisible ? 1 : 0,
    config: { tension: 100, friction: 30 }, // Leggero ease-in
  });

  return (
    <a.div id="skills" style={props} className="text-[#242424] dark:text-[#F5F5F7] p-10">
      <h3 className="mt-10 text-center w-full font-semibold text-4xl mb-5 p-3">
        Competenze
      </h3>
      <div className="max-w-5xl flex flex-row items-center place-content-center gap-5">
        <div className="flex flex-col gap-5 ">
          {slides.map((s, index) => {
            return (
              <ButtonShow title={s.title} textColor={s.mainColor} textDirection={s.textDirection} key={index} icon={s.icon} hiddenText={s.text}/>
            );
          })}
        </div>
      </div>
    </a.div>
  );
};

export default Skills;
